<div class="container">
    <div>
        <div class="content">
            <h1>Privacy policy and disclaimer "Explain-TB" </h1>
            <label>Latest Update:</label> May 2021
            <h3>General information</h3>
            <p>
                “Explain-TB” is a free-of-charge-App, which provides you with multilingual information on all aspects of
                tuberculosis. It also contains audio and videos about tuberculosis in various languages. The App does
                not collect any personal information, which can be related to you personally, e.g. name, IP address,
                email address, phone number, location, etc.
            </p>
            <p>
                The party responsible according to Art. 4 (7) of the EU General Data Protection Regulation (GDPR/DS-GVO)
                is:
            </p>
            <p>
            <h4>DZK – German Central Committee against tuberculosis e.V. </h4>
            Waltehöferstr. 11, House Q<br />
            14165 Berlin
            Germany
            <p>
                Email: info@dzk-tuberkulose.de<br />
            </p>
            </p>
            <h3>General log files</h3>
            <p>
                Each time you access the app, certain information is automatically sent from your mobile device to the
                app's server, which then stores it in a so-called log file. This is information about:
            </p>
            <ul>
                <li>
                    <p class="bullet">the operating system used</p>
                </li>
                <li>
                    <p class="bullet">the time at which the app was accessed</p>
                </li>
            </ul>
            <p>
                The legal basis for this processing is Art. 6.1.1f DSGVO. The required legitimate interest follows from
                the above-mentioned purposes.
            </p>
            <h3>We don’t collect, process, store or use personal data</h3>
            <p>
                The App is intended as information and translation tool. Therefore no personal data is collected, stored
                or passed on third parties. We inform you about your right to contact the responsible supervisory
                authority in case of complaints. The supervisory authority responsible for the controller is:
            </p>
            <p>
            <h4>Berlin Commissioner for Data Protection and Freedom of Information<br />Maja Smoltczyk</h4>
            Friedrichstr. 219<br />
            10969 Berlin
            Germany
            <p>
                Phone: +49 30 13889-0<br />
                Email: info@dzk-tuberkulose.de
            </p>
            </p>
            <h3>Information collected when downloading “Explain-TB” from the app store.</h3>
            <p>
                When downloading “Explain-TB” from your selected app store, various data such as username, e-mail etc.
                are sent. The processing of this data is carried out exclusively by the respective app store and is not
                subject to our influence.
            </p>
            <h3>Liability / Limitation of liability</h3>
            <p>
                The contents of our app were created with the greatest possible care and to the best of our knowledge.
                However, we do not assume any liability for the topicality, completeness and correctness of the pages.
                According to § 7 para. 1 TMG (Telemedia Act), we as a service provider are responsible for our own
                content on these pages under the general laws. According to § 8 to 10 TMG, however, we are not obligated
                as a service provider to monitor transmitted or stored third-party information. If we become aware of
                any such infringements, we will remove the relevant content immediately. However, liability in this
                regard is only possible from the time of knowledge of a concrete infringement.
            </p>
            <h3>Medical disclaimer</h3>
            <p>
                The app Explain-TB is designed to give personalized information on TB, TB diagnosis and treatment. The
                information is available in 36 languages, which can be selected seperately. It does not replace the
                contact with your responsible doctors and your responsible health authorities.
            </p>
            <h3>Limitation of liability for external links</h3>
            <p>
                Our website contains links to websites of third parties ("external links"), which are outside our area
                of responsibility. The information provider of the linked websites is liable for the content and
                accuracy of the information. At the time of linking, we were not aware of any legal violations. If we
                become aware of any legal violations, we will remove the corresponding link immediately.
            </p>
            <h3>Copyright</h3>
            <p>
                The content published on the website/ in the app "Explain-TB” is subject to German copyright law. The
                reproduction, editing, distribution and any kind of exploitation outside the limits of copyright require
                the prior written consent of the respective author or creator.
            </p>
            <h3>Change of the privacy policy</h3>
            <p>
                The app “Explain-TB” is continuously developed during use in order to improve its functionality and
                user-friendliness. The privacy policy is always kept up to date and adapted accordingly. Users will be
                informed of any changes to the privacy policy in a timely manner. The current privacy policy can be
                viewed at any time within the app’s website.
            </p>
        </div>
    </div>
</div>