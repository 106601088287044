import axios, { AxiosRequestConfig } from 'axios';

axios.defaults.withCredentials = false;

export default class ApiService
{
    static API_BASE_PATH: string = `https://${process.env.VUE_APP_API_PATH}/`;

    public static async SendMessage(email : string, message : string)
    {
        try
        {
            let formData : FormData = new FormData();
            formData.append('api', 'contact');
            formData.append('cmd', 'message');
            formData.append('email', email);
            formData.append('message', message);
            formData.append('token', '72012b3db8b4fe25d94fe63b7916c520fbd63928471b81e7016526e57187ad46a3d3e3b0291b73540971df7643f748eb7bc76c62115682e44a0bb1311a88ca18');

            let response = await axios.post(`${ApiService.API_BASE_PATH}`, formData);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data;
                }
                else
                {
                    console.log(response);
                }
            }
        }
        catch (error)
        {
            console.log(error);
        }
    }
}