import { Component, Vue } from 'vue-facing-decorator';
import Navigation from '@/components/Navigation/Navigation.vue';
import Language from '@/components/Language/Language.vue';
import AOS from 'aos';

@Component(
{
    components:
    {
        Navigation,
        Language
    },
})

export default class Support extends Vue 
{
    private loading : boolean = true;
    private showCaret : boolean = false;
    private lang : string = 'en';
    private windowWidth : number = window.innerWidth;
    private windowHeight : number = window.innerHeight;
    
    private async mounted()
    {
        // initialize action on scroll
        AOS.init();

        // fetch language from params
        this.lang = this.$route.params.lang as string;

        // add listener for resize event
        window.addEventListener('resize', this.onWindowResize);

        // scroll to top
        window.scrollTo(0,0);

        // set loading indicator
        this.loading = false;
    }

    private onWindowResize(e : any)
    {
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
    }
}