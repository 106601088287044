<div class="container">
    <Navigation />
    <Language />
    <div v-if="lang=='en'">
        <div :class="windowWidth > 1000 ? 'content-screen' : 'content'">
            <div class="text-medium" data-aos="fade-up" data-aos-easing="ease">
                You have TB?
            </div>
            <div class="text-medium" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="500">
                You need more information?
            </div>
            <div class="text-medium" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="1000">
                Need some company and support for your therapy?
            </div>
            <div class="text-medium" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="1500">
                Anonymous and free of charge?
            </div>
            <div class="caret-slot" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="2000">
                <transition name="el-fade-in-linear">
                    <el-icon v-show="showCaret"><CaretBottom /></el-icon>
                </transition>
            </div>
            <div v-if="windowWidth > 720" class="text-large" data-aos="flip-right" data-aos-easing="ease" data-aos-delay="300">
                AVAILABLE NOW!
            </div>
            <el-row class="info-row">
                <el-col :span="24" class="info-col">
                    <div data-aos="fade-up" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image src="/logo_large.png"></el-image>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="col-right-huge">
                            Choose your companion and get started.
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="zoom-in" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image src="/1_happy.png"></el-image>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <div data-aos="zoom-in" data-aos-easing="ease">
            <el-row class="info-row">
                    <el-col :span="8" class="info-col">
                        <div class="col-right">
                            <el-image src="/2_happy.png"></el-image>
                        </div>
                    </el-col>
                    <el-col :span="6" class="info-col">
                        <div class="col-companions">
                            <el-image class="hippo" src="/3_happy.png"></el-image>
                        </div>
                    </el-col>
                    <el-col :span="6" class="info-col">
                        <div class="col-companions">
                            <el-image class="tiger" src="/4_happy.png"></el-image>
                        </div>
                    </el-col>
            </el-row>
            </div>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image :class="windowWidth > 1000 ? 'desktop' : 'mobile'" src="/screen03.png"></el-image>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-left" data-aos-easing="ease">
                        <div class="screen-text-1">
                            Virtual support, if you are feeling lonely or afraid.
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="screen-text-2">
                            Setup your therapy period to watch your progress.
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-left" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image :class="windowWidth > 1000 ? 'desktop-2' : 'mobile'" src="/screen07.jpg">
                            </el-image>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image :class="windowWidth > 1000 ? 'desktop' : 'mobile'" src="/screen04.jpg"></el-image>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-left" data-aos-easing="ease">
                        <div class="screen-text-3">
                            Enter your medication plan.
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="screen-text-4">
                            Daily treatment reminders helping you to stay on track.
                            <br /><br />
                            <el-image src="/screen11.png"></el-image>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-left" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image :class="windowWidth > 1000 ? 'desktop-3' : 'mobile'" src="/screen01.jpg">
                            </el-image>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image src="/screen02.png"></el-image>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-left" data-aos-easing="ease">
                        <div class="screen-text-5">
                            Tracks progress and supports you even with legal advice during TB treatment.
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="screen-text-6">
                            Information about TB disease in general.
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-left" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image :class="windowWidth > 1000 ? 'desktop-2' : 'mobile'" src="/screen09.png">
                            </el-image>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image :class="windowWidth > 1000 ? 'desktop' : 'mobile'" src="/screen10.png"></el-image>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-left" data-aos-easing="ease">
                        <div class="screen-text-7">
                            Information on how to get cured and how to protect others.
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="screen-text-8">
                            Check your complete chat history.<br /><br />
                            Opt-out anytime you want.
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-left" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image :class="windowWidth > 1000 ? 'desktop' : 'mobile'" src="/screen05.jpg"></el-image>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="24" class="info-col">
                    <div data-aos="flip-right" data-aos-easing="ease">
                        <div class="col-right">
                            Interested?
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="24" class="info-col">
                    <div data-aos="fade-up" data-aos-easing="ease">
                        <div class="col-right">
                            Ask your healthcare worker in this institution
                            or look for TB Companion on Apple App Store or Google Play.
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="24" class="badge-buttons">
                    <a href="https://play.google.com/store/apps/details?id=app.tbcompanion" target="_blank">
                        <el-image class="badge" src="/google-play.png"></el-image>
                    </a>
                    <a href="https://apps.apple.com/us/app/tb-companion/id1556445437" target="_blank">
                        <el-image class="badge" src="/app-store.svg"></el-image>
                    </a>
                </el-col>
            </el-row>
        </div>
    </div>
    <div v-if="lang=='ro'">
        <div :class="windowWidth > 1000 ? 'content-screen' : 'content'">
            <div class="text-medium" data-aos="fade-up" data-aos-easing="ease">
                Aveți tuberculoză?
            </div>
            <div class="text-medium" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="500">
                Aveți nevoie de mai multe informații?
            </div>
            <div class="text-medium" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="1000">
                Aveți nevoie de companie și susținere pe timpul terapiei?
            </div>
            <div class="text-medium" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="1500">
                Anonim și fără niciun cost?
            </div>
            <div class="caret-slot" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="2000">
                <transition name="el-fade-in-linear">
                    <el-icon v-show="showCaret" class="el-icon-caret-bottom"></el-icon>
                </transition>
            </div>
            <div v-if="windowWidth > 720" class="text-large" data-aos="flip-right" data-aos-easing="ease"
                data-aos-delay="2000">
                DISPONIBIL ACUM!
            </div>
            <el-row class="info-row">
                <el-col :span="24" class="info-col">
                    <div data-aos="fade-up" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image src="/logo_large.png"></el-image>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="col-right-huge">
                            Alegeți-vă partenerul și începeți.
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="zoom-in" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image src="/1_happy.png"></el-image>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <div data-aos="zoom-in" data-aos-easing="ease">
                    <el-col :span="8" class="info-col">
                        <div class="col-right">
                            <el-image src="/2_happy.png"></el-image>
                        </div>
                    </el-col>
                    <el-col :span="6" class="info-col">
                        <div class="col-companions">
                            <el-image class="hippo" src="/3_happy.png"></el-image>
                        </div>
                    </el-col>
                    <el-col :span="6" class="info-col">
                        <div class="col-companions">
                            <el-image class="tiger" src="/4_happy.png"></el-image>
                        </div>
                    </el-col>
                </div>
            </el-row>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image :class="windowWidth > 1000 ? 'desktop' : 'mobile'" src="/screen03.png"></el-image>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-left" data-aos-easing="ease">
                        <div class="screen-text-1">
                            Susținere virtuală dacă vă simțiți singur/ă.
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="screen-text-2">
                            Configurați perioada de terapie pentru a vă urmări progresul.
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-left" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image :class="windowWidth > 1000 ? 'desktop-2' : 'mobile'" src="/screen07.jpg">
                            </el-image>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image :class="windowWidth > 1000 ? 'desktop' : 'mobile'" src="/screen04.jpg"></el-image>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-left" data-aos-easing="ease">
                        <div class="screen-text-3">
                            Introduceți planul de medicamente.
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="screen-text-4">
                            Memento-uri de tratament zilnic, care vă ajută să rămâneți pe drumul cel bun.
                            <br /><br />
                            <el-image src="/screen11.png"></el-image>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-left" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image :class="windowWidth > 1000 ? 'desktop-3' : 'mobile'" src="/screen01.jpg">
                            </el-image>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image src="/screen02.png"></el-image>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-left" data-aos-easing="ease">
                        <div class="screen-text-5">
                            Urmărește progresul și vă sprijină chiar și cu sfaturi juridice în timpul tratamentului de tuberculoză.
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="screen-text-6">
                            Informații generale despre tuberculoză.
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-left" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image :class="windowWidth > 1000 ? 'desktop-2' : 'mobile'" src="/screen09.png">
                            </el-image>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image :class="windowWidth > 1000 ? 'desktop' : 'mobile'" src="/screen10.png"></el-image>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-left" data-aos-easing="ease">
                        <div class="screen-text-7">
                            Informații despre cum să vă vindecați și cum să-i protejați pe ceilalți.
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="screen-text-8">
                            Verificați istoricul complet al conversațiilor.<br /><br />
                            Renunțați oricând doriți.
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-left" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image :class="windowWidth > 1000 ? 'desktop' : 'mobile'" src="/screen05.jpg"></el-image>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="24" class="info-col">
                    <div data-aos="flip-right" data-aos-easing="ease">
                        <div class="col-right">
                            Interesat/ă?
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="24" class="info-col">
                    <div data-aos="fade-up" data-aos-easing="ease">
                        <div class="col-right">
                            Adresați-vă personalului medical din această instituție sau căutați TB Companion în Apple App Store sau Google Play.
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="24" class="badge-buttons">
                    <a href="https://play.google.com/store/apps/details?id=app.tbcompanion" target="_blank">
                        <el-image class="badge" src="/google-play.png"></el-image>
                    </a>
                    <a href="https://apps.apple.com/us/app/tb-companion/id1556445437" target="_blank">
                        <el-image class="badge" src="/app-store.svg"></el-image>
                    </a>
                </el-col>
            </el-row>
        </div>
    </div>
    <div v-if="lang=='uk'">
        <div :class="windowWidth > 1000 ? 'content-screen' : 'content'">
            <div class="text-medium" data-aos="fade-up" data-aos-easing="ease">
                Захворів на туберкульоз?
            </div>
            <div class="text-medium" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="500">
                Вам потрібно більше інформації?
            </div>
            <div class="text-medium" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="1000">
                Потрібна компанія та підтримка під  час лікування?
            </div>
            <div class="text-medium" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="1500">
                Бажаєте зробити це анонімно і безкоштовно?
            </div>
            <div class="caret-slot" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="2000">
                <transition name="el-fade-in-linear">
                    <el-icon v-show="showCaret" class="el-icon-caret-bottom"></el-icon>
                </transition>
            </div>
            <div v-if="windowWidth > 720" class="text-large" data-aos="flip-right" data-aos-easing="ease"
                data-aos-delay="2000">
                ЗАРАЗ ВСЕ це є!
            </div>
            <el-row class="info-row">
                <el-col :span="24" class="info-col">
                    <div data-aos="fade-up" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image src="/logo_large.png"></el-image>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="col-right-huge">
                            Оберіть свого компаньйона і починайте.
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="zoom-in" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image src="/1_happy.png"></el-image>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <div data-aos="zoom-in" data-aos-easing="ease">
                    <el-col :span="8" class="info-col">
                        <div class="col-right">
                            <el-image src="/2_happy.png"></el-image>
                        </div>
                    </el-col>
                    <el-col :span="6" class="info-col">
                        <div class="col-companions">
                            <el-image class="hippo" src="/3_happy.png"></el-image>
                        </div>
                    </el-col>
                    <el-col :span="6" class="info-col">
                        <div class="col-companions">
                            <el-image class="tiger" src="/4_happy.png"></el-image>
                        </div>
                    </el-col>
                </div>
            </el-row>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image :class="windowWidth > 1000 ? 'desktop' : 'mobile'" src="/screen03.png"></el-image>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-left" data-aos-easing="ease">
                        <div class="screen-text-1">
                            Якщо ви відчуваєте самотність або страх, ви можете отримати віртуальну підтримку.
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="screen-text-2">
                            Налаштуйте період лікування і спостерігайте за прогресом.
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-left" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image :class="windowWidth > 1000 ? 'desktop-2' : 'mobile'" src="/screen07.jpg">
                            </el-image>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image :class="windowWidth > 1000 ? 'desktop' : 'mobile'" src="/screen04.jpg"></el-image>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-left" data-aos-easing="ease">
                        <div class="screen-text-3">
                            Введіть свій план лікування.
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="screen-text-4">
                            Щоденні нагадування про лікування допоможуть триматися заданого курсу.
                            <br /><br />
                            <el-image src="/screen11.png"></el-image>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-left" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image :class="windowWidth > 1000 ? 'desktop-3' : 'mobile'" src="/screen01.jpg">
                            </el-image>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image src="/screen02.png"></el-image>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-left" data-aos-easing="ease">
                        <div class="screen-text-5">
                            Під час лікування туберкульозу ви можете відстежувати результат і навіть отримувати юридичну підтримку.
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="screen-text-6">
                            Загальна інформація про захворювання на туберкульоз.
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-left" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image :class="windowWidth > 1000 ? 'desktop-2' : 'mobile'" src="/screen09.png">
                            </el-image>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image :class="windowWidth > 1000 ? 'desktop' : 'mobile'" src="/screen10.png"></el-image>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-left" data-aos-easing="ease">
                        <div class="screen-text-7">
                            нформація про те, як вилікуватися та як захистити інших.
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-right" data-aos-easing="ease">
                        <div class="screen-text-8">
                            Ви можете переглядати всю хронологію чату.<br /><br />
                            Відмовитися можна будь-коли.
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="info-col">
                    <div data-aos="fade-up-left" data-aos-easing="ease">
                        <div class="col-left">
                            <el-image :class="windowWidth > 1000 ? 'desktop' : 'mobile'" src="/screen05.jpg"></el-image>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="24" class="info-col">
                    <div data-aos="flip-right" data-aos-easing="ease">
                        <div class="col-right">
                            Зацікавились?
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="24" class="info-col">
                    <div data-aos="fade-up" data-aos-easing="ease">
                        <div class="col-right">
                            Запитайте свого медичного працівника в цій установі або знайдіть проект TB Companion у додатках Apple App Store або Google Play.
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="24" class="badge-buttons">
                    <a href="https://play.google.com/store/apps/details?id=app.tbcompanion" target="_blank">
                        <el-image class="badge" src="/google-play.png"></el-image>
                    </a>
                    <a href="https://apps.apple.com/us/app/tb-companion/id1556445437" target="_blank">
                        <el-image class="badge" src="/app-store.svg"></el-image>
                    </a>
                </el-col>
            </el-row>
        </div>
    </div>
    <img class="bannerimage" src="/banner.png" alt="banner" />
</div>