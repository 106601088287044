import { Component, Vue } from 'vue-facing-decorator';
import { ElMessage } from 'element-plus';
import ApiService from '@/services/ApiService';
import Navigation from '@/components/Navigation/Navigation.vue';
import Language from '@/components/Language/Language.vue';

@Component(
{
    components:
    {
        Navigation,
        Language
    },
})

export default class Contact extends Vue 
{
    private loading : boolean = true;
    private form : ContactForm = {} as ContactForm;
    private isFormValid : boolean = false
    private isSending : boolean = false;
    private hints : string = "";
    private lang : string = 'en';
    private windowWidth : number = window.innerWidth;
    private windowHeight : number = window.innerHeight;
    
    private async mounted()
    {
        // fetch language from params
        this.lang = this.$route.params.lang as string;

        // set loading indicator
        this.loading = false;

        window.addEventListener('resize', this.onWindowResize);
        window.scrollTo(0,0);
    }

    private onWindowResize(e : any)
    {
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
    }

    private validateEmail()
    {
        if (!this.form.email || this.form.email.length <= 0) return false;

        let re : RegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return re.test(this.form.email);
    }

    private validateMessage()
    {
        return this.form.message != null && this.form.message.length > 0;
    }

    private validateForm()
    {
        this.hints = "";
        this.isFormValid = false;

        if (!this.validateEmail())
        {
            switch (this.lang)
            {
                case "en":
                    this.hints = "Please provide a valid email address.";
                    break;

                case "ro":
                    this.hints = "Vă rugăm să furnizați o adresă de e-mail validă.";
                    break;
                
                case "uk":
                    this.hints = "Укажіть дійсну електронну адресу.";
                    break;
            };
            
            return;
        } 

        if (!this.validateMessage())
        {
            switch (this.lang)
            {
                case "en":
                    this.hints = "Please enter a message.";
                    break;

                case "ro":
                    this.hints = "Vă rugăm să introduceți un mesaj.";
                    break;
                
                case "uk":
                    this.hints = "Будь ласка, введіть повідомлення.";
                    break;
            };
            
            return;
        }

        this.isFormValid = true;
    }

    private async SendMessage()
    {
        let ok : boolean = await ApiService.SendMessage(this.form.email, this.form.message);

        this.isSending = false;

        if (ok)
        {
            switch (this.lang)
            {
                case "en":
                    ElMessage(
                    {
                            message: "Message received. Thank you!",
                            type: 'success',
                    });
                    break;

                case "ro":
                    ElMessage(
                    {
                            message: "Mesaj receptionat. Mulțumesc!",
                            type: 'success',
                    });
                    break;

                case "uk":
                    ElMessage(
                    {
                            message: "Повідомлення отримано. Дякую!",
                            type: 'success',
                    });
                    break;
            };
            
            this.form = {} as ContactForm;
        }
        else
        {
            switch (this.lang)
            {
                case "en":
                    ElMessage(
                    {
                            message: "Uh oh... something went wrong. Please try again later.",
                            type: 'error',
                    });
                    break;

                case "ro":
                    ElMessage(
                    {
                            message: "Uh oh... ceva a mers prost. Vă rugăm să încercați din nou mai târziu.",
                            type: 'error',
                    });
                    break;
                
                case "uk":
                    ElMessage(
                    {
                            message: "Ой... щось пішло не так. Будь-ласка спробуйте пізніше.",
                            type: 'error',
                    });
                    break;
            };
        }
    }

    public onInput()
    {
        this.validateForm();
    }

    public onSendClick()
    {
        if (this.isFormValid)
        {
            this.isSending = true;
            this.SendMessage();
        }
    }
}