<div class="container">
    <Navigation />
    <Language />
    <div v-if="lang=='en'">
        <div class="content">
            <h1>Contact</h1>
            <div class="hotline">
                Study hotline: <span class="phone">+49 (0)17 85 28 16 43</span>
            </div>
            <p>Get in touch with us by sending us a quick feedback.</p>
            <div class="contact-form">
                <el-form ref="contact_form" label-position="top" action="#" :model="form" label-width="120px">
                    <label class="bold">Your Email:</label>
                    <el-form-item prop="email">
                        <el-input autofocus v-model="form.email" :disabled="isSending" @change="onInput"></el-input>
                    </el-form-item>
                    <label class="bold">Your Message to us:</label>
                    <el-form-item prop="message">
                        <el-input type="textarea" rows="8" v-model="form.message" :disabled="isSending" @input="onInput"></el-input>
                    </el-form-item>
                    <p class="send-button">
                        <el-tag v-if="!isFormValid && hints.length > 0" type="warning">{{hints}}</el-tag>
                        <el-button v-show="isFormValid" type="success" class="right" :disabled="!isFormValid || isSending" :loading="isSending" @click="onSendClick">
                            Send
                        </el-button>
                    </p>
                </el-form>
            </div>
        </div>
        <img class="bannerimage" src="/banner.png" alt="banner" />
    </div>
    <div v-if="lang=='ro'">
        <div class="content">
            <h1>a lua legatura</h1>
            <div class="hotline">
                Linia fierbinte pentru studii: <span class="phone">+49 (0)17 85 28 16 43</span>
            </div>
            <p>Luați legătura cu noi trimițându-ne un feedback rapid.</p>
            <div class="contact-form">
                <el-form ref="contact_form" label-position="top" action="#" :model="form" label-width="120px">
                    <label class="bold">Email-ul tau:</label>
                    <el-form-item prop="email">
                        <el-input autofocus v-model="form.email" :disabled="isSending" @change="onInput"></el-input>
                    </el-form-item>
                    <label class="bold">Mesajul dvs. pentru noi:</label>
                    <el-form-item prop="message">
                        <el-input type="textarea" rows="8" v-model="form.message" :disabled="isSending" @input="onInput"></el-input>
                    </el-form-item>
                    <p class="send-button">
                    <el-tag v-if="!isFormValid && hints.length > 0" type="warning">{{hints}}</el-tag>
                    <el-button v-show="isFormValid" type="success" class="right" :disabled="!isFormValid || isSending" :loading="isSending" @click="onSendClick">
                        Send
                    </el-button>
                    </p>
                </el-form>
            </div>
        </div>
        <img class="bannerimage" src="/banner.png" alt="banner" />
    </div>
    <div v-if="lang=='uk'">
        <div class="content">
            <h1>контакт</h1>
            <div class="hotline">
                Гаряча лінія навчання: <span class="phone">+49 (0)17 85 28 16 43</span>
            </div>
            <p>Зв’яжіться з нами, надіславши швидкий відгук.</p>
            <div class="contact-form">
                <el-form ref="contact_form" label-position="top" action="#" :model="form" label-width="120px">
                    <label class="bold">Твоя електронна пошта:</label>
                    <el-form-item prop="email">
                        <el-input autofocus v-model="form.email" :disabled="isSending" @change="onInput"></el-input>
                    </el-form-item>
                    <label class="bold">Ваше повідомлення до нас:</label>
                    <el-form-item prop="message">
                        <el-input type="textarea" rows="8" v-model="form.message" :disabled="isSending" @input="onInput"></el-input>
                    </el-form-item>
                    <p class="send-button">
                    <el-tag v-if="!isFormValid && hints.length > 0" type="warning">{{hints}}</el-tag>
                    <el-button v-show="isFormValid" type="success" class="right" :disabled="!isFormValid || isSending" :loading="isSending" @click="onSendClick">
                        Send
                    </el-button>
                    </p>
                </el-form>
            </div>
        </div>
        <img class="bannerimage" src="/banner.png" alt="banner" />
    </div>
</div>