<div class="container">
    <Navigation />
    <Language />
    <transition name="el-fade-in-linear">
        <img v-show="showLogo" class="logo" src="/logo_large.png" alt="TBCompanion logo" />
    </transition>
    <transition name="el-fade-in-linear">
        <div v-show="showBubble" class="bubble">
            <div class="bubble-top">
                <el-image class="bubble-top" src="/bubble_top.png"></el-image>
            </div>
            <div class="bubble-center">
                <span v-show="bubbleText.length > 0">{{ bubbleText }}</span>
            </div>
            <div class="bubble-bottom">
                <el-image class="bubble-bottom" src="/bubble_bottom.png"></el-image>
            </div>
        </div>
    </transition>
    <transition name="el-zoom-in-bottom">
        <el-image v-show="showPanda" class="panda" src="/1_happy.png"></el-image>
    </transition>
    <transition name="el-zoom-in-bottom">
        <el-image v-show="showPanda && windowWidth > 500" class="bear" src="/2_happy.png"></el-image>
    </transition>
    <transition name="el-zoom-in-bottom">
        <el-image v-show="showPanda && windowWidth > 1000" class="hippo" src="/3_happy.png"></el-image>
    </transition>
    <transition name="el-zoom-in-bottom">
        <el-image v-show="showPanda && windowWidth > 1000" class="tiger" src="/4_happy.png"></el-image>
    </transition>
    <div class="infoblock" v-show="showInfo">
        <a href="/about" ><el-image class="logo" src="/logo_large.png" alt="TBCompanion logo"></el-image></a><br/>
        <a href="https://play.google.com/store/apps/details?id=app.tbcompanion" target="_blank"><el-image class="badge" src="/google-play.png"></el-image></a>
        <a href="https://apps.apple.com/us/app/tb-companion/id1556445437" target="_blank"><el-image class="badge" src="/app-store.svg"></el-image></a>
    </div>
    <img class="bannerimage" src="/banner.png" alt="banner" />
</div>