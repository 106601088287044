import { Component, Vue } from 'vue-facing-decorator';

@Component(
{
    components:
    {
    },
})

export default class Navigation extends Vue 
{
    private language : string = 'en';
    private textAbout : string = 'About';
    private textTeam : string = 'Team';
    private textRoger : string = 'RoGer TB';
    private textContact : string = 'Contact';
    private textPrivacy : string = 'Privacy Policy';
    private textImprint : string = 'Imprint';
    private textSupport : string = 'Support';
    private windowWidth : number = window.innerWidth;
    private windowHeight : number = window.innerHeight;

    private async mounted()
    {
        // get language from url parameter, or load from local storage
        if (this.$route.params.lang)
        {
            this.language = this.$route.params.lang as string;
        }

        // if no language is given, try to set according to browser settings
        if (!this.language || this.language == '?')
        {
            if (navigator.language.toLowerCase().includes('ro'))
            {
                this.language = 'ro';
            }
           
            if (navigator.language.toLowerCase().includes('uk'))
            {
                this.language = 'uk';
            }
        }

        window.addEventListener('resize', this.onWindowResize);
        window.scrollTo(0,0);
    }

    private onWindowResize(e : any)
    {
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
    }

    public handleSelect(key : number) 
    {
        switch (key)
        {
            case 0:
                this.$router.push(`/home/${this.language}`).catch(()=>{});
                break;

            case 1:
                this.$router.push(`/about/${this.language}`).catch(()=>{});
                break;
            
            case 2:
                this.$router.push(`/team/${this.language}`).catch(()=>{});
                break;

            case 3:
                this.$router.push(`/roger/${this.language}`).catch(()=>{});
                break;

            case 4:
                this.$router.push(`/contact/${this.language}`).catch(()=>{});
                break;

            case 5:
                this.$router.push(`/privacy/${this.language}`).catch(()=>{});
                break;

            case 6:
                this.$router.push(`/imprint/${this.language}`).catch(()=>{});
                break;
            
            case 7:
                this.$router.push(`/support/${this.language}`).catch(()=>{});
                break;
        }
    }
}
