<div class="container">
    <Navigation />
    <Language />
    <div class="placeholder" v-if="lang=='en'">
        <div class="team">
            <div class="image">
                <el-image src="https://tbcompanion.app/img/companions/1_happy.png"></el-image>
            </div>
            <div :class="windowWidth > 600 ? 'text-left' : 'text-left-mobile'">
                <p class="name">Christian Herzmann</p>
                <p class="profession">Medical doctor specialized in respiratory medicine and tuberculosis</p>
                <p class="info">While visiting a Tuberculosis-conference, he had that great idea of an App, which
                    accompanies TB-patients during their treatment. The idea of the TBCompanion was born. He developed
                    the App together with Rick, wrote most of the dialogues and set up the scenery of TBCompanion. He
                    works at the Research Center Borstel and is also the founder of <a class="info"
                        href="https://www.explaintb.org">ExplainTB!</a></p>
            </div>
        </div>
        <div class="team">
            <div v-if="windowWidth < 600" class="image">
                <el-image src="https://tbcompanion.app/img/companions/4_happy.png"></el-image>
            </div>
            <div :class="windowWidth >= 600 ? 'text-right' : 'text-left-mobile'">
                <p class="name">Ralf Otto-Knapp</p>
                <p class="profession">Medical doctor specialized in respiratory medicine, infectious disease and
                    tuberculosis</p>
                <p class="info">Ralf works together with Brit at the DZK – an organization for all aspects of TB in
                    Germany. Ralf is passionate about making the APP user friendly and developed a lot of the dialogues
                    together with Christian and was responsible for the finetuning of the APP.</p>
            </div>
            <div v-if="windowWidth >= 600" class="image">
                <el-image src="https://tbcompanion.app/img/companions/4_happy.png"></el-image>
            </div>
        </div>
        <div class="team">
            <div class="image">
                <el-image src="https://tbcompanion.app/img/companions/2_happy.png"></el-image>
            </div>
            <div :class="windowWidth > 600 ? 'text-left' : 'text-left-mobile'">
                <p class="name">Brit Häcker</p>
                <p class="profession">Medical doctor in the field of respiratory medicine and tuberculosis</p>
                <br/>
                <p class="name">Christine Trost</p>
                <p class="profession">Administration, Project Management</p>
                <p class="info">They work together with Ralf at the DZK – an organization for all aspects of TB in
                    Germany. Brit hates losing the fight against these mycobacteria and deals together with Christine with most of the
                    organizational stuff for TBCompanion.</p>
            </div>
        </div>
        <div class="team">
            <div v-if="windowWidth < 600" class="image">
                <el-image src="https://tbcompanion.app/img/companions/3_happy.png"></el-image>
            </div>
            <div :class="windowWidth >= 600 ? 'text-right' : 'text-left-mobile'">
                <p class="name">Vanessa Igbokwe</p>
                <p class="profession">Medical student</p>
                <p class="info">Vanessa is a final-year medical student and works at DZK next to her studies. She has a high interest in infectious diseases and tuberculosis, and, still being a learner herself, she believes that patient education is the key to patient empowerment. Within the TB Companion study, she helps mostly with patient recruitment.</p>
            </div>
            <div v-if="windowWidth >= 600" class="image">
                <el-image src="https://tbcompanion.app/img/companions/3_happy.png"></el-image>
            </div>
        </div>
        <div class="team">
            <div class="image">
                <el-image src="https://tbcompanion.app/img/companions/2_happy.png"></el-image>
            </div>
            <div :class="windowWidth > 600 ? 'text-left' : 'text-left-mobile'">
                <p class="name">Estera Pușcașu</p>
                <p class="profession">Medical student</p>
                <p class="info">Estera is part of the DZK Team and likes building a relationship based on trust with the patients. She supports the team by recruiting and counselling patients. Being a native Romanian, this project was close to her heart. Speaking German, English and Romanian proved to be an important asset to the project.</p>
            </div>
        </div>
        <div class="team">
            <div v-if="windowWidth < 600" class="image">
            </div>
            <div :class="windowWidth >= 600 ? 'text-right' : 'text-left-mobile'">
                <p class="name">Rick Shaikh</p>
                <p class="profession">Software Engineer</p>
                <p class="info">Rick is the technincal lead and developer behind TBCompanion. He brings all our
                    ideas to life and is responsible for programming and maintaining the TBCompanion-App, so your companion can now support you through your
                    TB treatment.</p>
            </div>
            <div v-if="windowWidth >= 600" class="image">
            </div>
        </div>

    </div>
    <div class="placeholder" v-if="lang=='ro'">
        <div class="team">
            <div class="image">
                <el-image src="https://tbcompanion.app/img/companions/1_happy.png"></el-image>
            </div>
            <div :class="windowWidth > 600 ? 'text-left' : 'text-left-mobile'">
                <p class="name">Christian Herzmann</p>
                <p class="profession">Medic specializat în medicină respiratorie și tuberculoză</p>
                <p class="info">În timp ce vizita o conferință de tuberculoză, el a avut o idee grozavă  a unei aplicații, care însoțește pacienții bolnavi de tuberculoză pe parcursul tratamentului lor. Așa s-a născut ideea de TBCompanion. El a dezvoltat aplicația împreună cu Rick, a scris majoritatea dialogurilor și a stabilit scenariul TBCompanion. Lucrează la Centrul de Cercetare Borstel și este, de asemenea, fondatorul <a class="info"
                        href="https://www.explaintb.org">ExplainTB.</a></p>
            </div>
        </div>
        <div class="team">
            <div v-if="windowWidth < 600" class="image">
                <el-image src="https://tbcompanion.app/img/companions/4_happy.png"></el-image>
            </div>
            <div :class="windowWidth >= 600 ? 'text-right' : 'text-left-mobile'">
                <p class="name">Ralf Otto-Knapp</p>
                <p class="profession">Medic specializat în medicină respiratorie, boli infecțioase și tuberculoză</p>
                <p class="info">Ralf colaborează cu Brit la DZK – o organizație care se ocupă cu toate aspectele legate de tuberculoză din Germania. Ralf este pasionat de a face aplicația ușor de utilizat și a dezvoltat o mulțime de dialoguri împreună cu Christian, fiind de asemenea responsabil pentru ajustarea aplicației.</p>
            </div>
            <div v-if="windowWidth >= 600" class="image">
                <el-image src="https://tbcompanion.app/img/companions/4_happy.png"></el-image>
            </div>
        </div>
        <div class="team">
            <div class="image">
                <el-image src="https://tbcompanion.app/img/companions/2_happy.png"></el-image>
            </div>
            <div :class="windowWidth > 600 ? 'text-left' : 'text-left-mobile'">
                <p class="name">Brit Häcker</p>
                <p class="profession">Medic în domeniul medicinei respiratorii și al tuberculozei</p>
                <br/>
                <p class="name">Christine Trost</p>
                <p class="profession">Administrație, management de proiect</p>
                <p class="info">Ele lucrează împreună cu Ralf la DZK – o organizație care se ocupă cu toate aspectele legate de tuberculoză din Germania. Brit urăște sa piardă lupta împotriva micobacteriilor și se ocupă, împreună cu Christine, de majoritatea lucrurilor organizaționale pentru TBCompanion.</p>
            </div>
        </div>
        <div class="team">
            <div v-if="windowWidth < 600" class="image">
                <el-image src="https://tbcompanion.app/img/companions/3_happy.png"></el-image>
            </div>
            <div :class="windowWidth >= 600 ? 'text-right' : 'text-left-mobile'">
                <p class="name">Vanessa Igbokwe</p>
                <p class="profession">Studentă la medicină</p>
                <p class="info">Vanessa e studentă la medicină în ultimul an și lucrează la DZK în paralel cu studiul. Are un interes ridicat în boli infecțioase și tuberculoză, și fiind o persoană care încă învață, ea crede ca educarea pacienților reprezintă cheia abilitării acestora. În cadrul studiului cu TB Companion, ea ajută mai ales cu recrutarea pacienților.</p>
            </div>
            <div v-if="windowWidth >= 600" class="image">
                <el-image src="https://tbcompanion.app/img/companions/3_happy.png"></el-image>
            </div>
        </div>
        <div class="team">
            <div class="image">
                <el-image src="https://tbcompanion.app/img/companions/2_happy.png"></el-image>
            </div>
            <div :class="windowWidth > 600 ? 'text-left' : 'text-left-mobile'">
                <p class="name">Estera Pușcașu</p>
                <p class="profession">Studentă la medicină</p>
                <p class="info">Face parte din echipa DZK și îi place să construiască relații bazate pe încredere cu pacienții. Susține echipa prin recrutarea și consilierea pacienților. Fiind româncă, s-a atașat rapid de acest proiect. Faptul că vorbește germană, engleză și română s-a dovedit a fi de un real folos.</p>
            </div>
        </div>

        <div class="team">
            <div v-if="windowWidth < 600" class="image">
            </div>
            <div :class="windowWidth >= 600 ? 'text-right' : 'text-left-mobile'">
                <p class="name">Rick Shaikh</p>
                <p class="profession">Inginer de software</p>
                <p class="info">Rick este liderul tehnic și developerul din spatele TBCompanion. El aduce la viață toate ideile noastre și este responsabil pentru programarea și menținerea  aplicației TBCompanion, astfel încât partenerul dvs. virtual  să vă poată sprijini pe parcursul tratamentului de tuberculoză.</p>
            </div>
            <div v-if="windowWidth >= 600" class="image">
            </div>
        </div>
    </div>
    <div class="placeholder" v-if="lang=='uk'">
        <div class="team">
            <div class="image">
                <el-image src="https://tbcompanion.app/img/companions/1_happy.png"></el-image>
            </div>
            <div :class="windowWidth > 600 ? 'text-left' : 'text-left-mobile'">
                <p class="name">Christian Herzmann</p>
                <p class="profession">Лікар за спеціальністю респіраторна медицина та туберкульоз</p>
                <p class="info">Під час відвідування конференції з туберкульозу у нього виникла чудова ідея створити прикладну програму, яка буде супроводжувати хворих на туберкульоз під час лікування. Так народився проект TB Companion. Разом із Ріком він розробив додаток, написав більшість діалогів і створив сценарій для TB Companion. Крістіан працює в дослідницькому центрі комуни Борстель, він є засновником проекту <a class="info"
                        href="https://www.explaintb.org">ExplainTB.</a></p>
            </div>
        </div>
        <div class="team">
            <div v-if="windowWidth < 600" class="image">
                <el-image src="https://tbcompanion.app/img/companions/4_happy.png"></el-image>
            </div>
            <div :class="windowWidth >= 600 ? 'text-right' : 'text-left-mobile'">
                <p class="name">Ralf Otto-Knapp</p>
                <p class="profession">Лікар за спеціальністю респіраторна медицина, інфекційні хвороби та туберкульоз</p>
                <p class="info">Ральф працює разом із Брітою в DZK – організації, яка займається усіма питаннями туберкульозу в Німеччині. Ральф пристрасно прагне зробити додаток зручним для користувача, для цього він розробив багато діалогів разом із Крістіаном і відповідав за точне налаштування додатку.</p>
            </div>
            <div v-if="windowWidth >= 600" class="image">
                <el-image src="https://tbcompanion.app/img/companions/4_happy.png"></el-image>
            </div>
        </div>
        <div class="team">
            <div class="image">
                <el-image src="https://tbcompanion.app/img/companions/2_happy.png"></el-image>
            </div>
            <div :class="windowWidth > 600 ? 'text-left' : 'text-left-mobile'">
                <p class="name">Brit Häcker</p>
                <p class="profession">Лікар в галузі респіраторної медицини та фтизіатрії</p>
                <br/>
                <p class="name">Christine Trost</p>
                <p class="profession">Адміністратор, менеджер проектів</p>
                <p class="info">Крістін працює разом з Ральфом в DZK – організації, яка займається усіма питаннями туберкульозу в Німеччині. Крістін не любить програвати у боротьбі з цими мікобактеріями та разом із Бріт займається більшістю організаційних питань у TB Companion.</p>
            </div>
        </div>
        <div class="team">
            <div v-if="windowWidth < 600" class="image">
                <el-image src="https://tbcompanion.app/img/companions/3_happy.png"></el-image>
            </div>
            <div :class="windowWidth >= 600 ? 'text-right' : 'text-left-mobile'">
                <p class="name">Rick Shaikh</p>
                <p class="profession">Розробник програмного забезпечення</p>
                <p class="info">Рік є технічним керівником і розробником програм для додатку TB Companion. Він втілює в життя всі наші ідеї та відповідає за програмування та підтримку TB Companion, щоб ваш компаньйон підтримував вас під час лікування туберкульозу.</p>
            </div>
            <div v-if="windowWidth >= 600" class="image">
                <el-image src="https://tbcompanion.app/img/companions/3_happy.png"></el-image>
            </div>
        </div>
    </div>
    <img class="bannerimage" src="/banner.png" alt="banner" />
</div>
