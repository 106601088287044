import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3cc0a93a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "top" }
const _hoisted_2 = { class: "home" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("img", {
        class: "topleft",
        src: "/logo.png",
        alt: "top logo",
        onClick: _cache[0] || (_cache[0] = $event => (_ctx.handleSelect(0)))
      })
    ]),
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.windowWidth > 500 ? 'title-full' : 'title-small'),
      onClick: _cache[1] || (_cache[1] = $event => (_ctx.handleSelect(1)))
    }, _toDisplayString(_ctx.textAbout), 3),
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.windowWidth > 500 ? 'title-full' : 'title-small'),
      onClick: _cache[2] || (_cache[2] = $event => (_ctx.handleSelect(2)))
    }, _toDisplayString(_ctx.textTeam), 3),
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.windowWidth > 500 ? 'title-full' : 'title-small'),
      onClick: _cache[3] || (_cache[3] = $event => (_ctx.handleSelect(3)))
    }, _toDisplayString(_ctx.textRoger), 3),
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.windowWidth > 500 ? 'title-full' : 'title-small'),
      onClick: _cache[4] || (_cache[4] = $event => (_ctx.handleSelect(4)))
    }, _toDisplayString(_ctx.textContact), 3),
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.windowWidth > 500 ? 'title-full' : 'title-small'),
      onClick: _cache[5] || (_cache[5] = $event => (_ctx.handleSelect(5)))
    }, _toDisplayString(_ctx.textPrivacy), 3),
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.windowWidth > 500 ? 'title-full' : 'title-small'),
      onClick: _cache[6] || (_cache[6] = $event => (_ctx.handleSelect(7)))
    }, _toDisplayString(_ctx.textSupport), 3),
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.windowWidth > 500 ? 'title-full' : 'title-small'),
      onClick: _cache[7] || (_cache[7] = $event => (_ctx.handleSelect(6)))
    }, _toDisplayString(_ctx.textImprint), 3)
  ]))
}