import { Component, Vue } from 'vue-facing-decorator';

@Component(
{
    components:
    {
    },
})

export default class Explaintb extends Vue 
{
    private loading : boolean = true;
    
    private async mounted()
    {
        // set loading indicator
        this.loading = false;
    }
}