import { Component, Vue } from 'vue-facing-decorator';
import Navigation from '@/components/Navigation/Navigation.vue';
import Language from '@/components/Language/Language.vue';

@Component(
{
    components:
    {
        Navigation,
        Language
    },
})

export default class Home extends Vue 
{
    private loading : boolean = true;
    private showLogo : boolean = false;
    private showBubble : boolean = false;
    private showPanda : boolean = false;
    private showInfo : boolean = false;
    private lang : string = 'en';
    private text1 : string = 'Got TB?';
    private text2 : string = 'Need support for your therapy?';
    private text3 : string = '';
    private text4 : string = 'Get TB COMPANION!';
    private bubbleText : string = '';
    private messageText : string = '';
    private windowWidth : number = window.innerWidth;
    private windowHeight : number = window.innerHeight;

    private async mounted()
    {
        // set loading indicator
        this.loading = false;
        
        // fetch language from params
        this.lang = this.$route.params.lang as string;

        // if no language is given, try to set according to browser settings
        if (!this.lang || this.lang == '?')
        {
            if (navigator.language.toLowerCase().includes('ro'))
            {
                this.lang = 'ro';
            }
            
            if (navigator.language.toLowerCase().includes('uk'))
            {
                this.lang = 'uk';
            }
        }

        switch (this.lang)
        {
            case 'ro':
                this.text1 = 'Ai TBC?';
                this.text2 = 'Ai nevoie de sprijin pentru terapia ta?';
                this.text3 = '';
                this.text4 = 'Obțineți TB COMPANION!';
            break;

            case 'uk':
                this.text1 = 'Захворів на TBC?';
                this.text2 = 'Потрібна підтримка Вашої терапії?';
                this.text3 = '';
                this.text4 = 'Завантажте TBCompanion!';
            break;

            default:
                break;
        };
        
        setTimeout(this.fadeInLogo, 500);

        window.addEventListener('resize', this.onWindowResize);
        window.scrollTo(0,0);
    }

    private onWindowResize(e : any)
    {
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
    }

    private async showMessage(message : string)
    {
        this.bubbleText = '';
        this.messageText = message;
        this.spoolText();
    }

    private async spoolText()
    {
        if (this.bubbleText.length < this.messageText.length)
        {
            this.bubbleText = this.messageText.substr(0, this.bubbleText.length+1);
            setTimeout(this.spoolText, 30);
        }
    }

    private async fadeInLogo()
    {
        this.showLogo = true;
        setTimeout(this.fadeOutLogo, 2000);
    }

    private async fadeOutLogo()
    {
        this.showLogo = false;
        setTimeout(this.fadeInBubble, 500);
    }

    private async fadeOutBubble()
    {
        this.showBubble = false;
    }

    private async fadePanda(show : boolean)
    {
        this.showPanda = show;
    }

    private async fadeInInfo()
    {
        this.showInfo = true;
    }

    private async fadeInBubble()
    {
        this.showBubble = true;
        this.showMessage(this.text1);
        setTimeout(this.showMessage, 2000, this.text2);
        setTimeout(this.showMessage, 4000, this.text3);
        setTimeout(this.showMessage, 4050, this.text4);
        setTimeout(this.fadePanda, 4000, true);
        setTimeout(this.fadePanda, 8000, false);
        setTimeout(this.fadeOutBubble, 8000);
        setTimeout(this.fadeInInfo, 8500);
    }
}