import { Component, Vue } from 'vue-facing-decorator';
import { CaretBottom } from '@element-plus/icons-vue'
import Navigation from '@/components/Navigation/Navigation.vue';
import Language from '@/components/Language/Language.vue';
import AOS from 'aos';

@Component(
{
    components:
    {
        Navigation,
        Language,
        CaretBottom
    },
})

export default class About extends Vue 
{
    private loading : boolean = true;
    private showCaret : boolean = false;
    private lang : string = 'en';
    private windowWidth : number = window.innerWidth;
    private windowHeight : number = window.innerHeight;
    
    private async mounted()
    {
        // initialize action on scroll
        AOS.init();

        // fetch language from params
        this.lang = this.$route.params.lang as string;
        
        // set loading indicator
        this.loading = false;

        this.animateCaret();

        window.addEventListener('resize', this.onWindowResize);
        window.scrollTo(0,0);
    }

    private onWindowResize(e : any)
    {
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
    }

    private animateCaret()
    {
        this.showCaret = !this.showCaret;
        setTimeout(this.animateCaret, 700);
    }
}