<div class="container">
    <Navigation />
    <Language />
    <div v-if="lang=='en'">
        <div class="content">
            <h1>Imprint</h1>
            <p>
                Imprint according to §6 MDStV
            </p>
            <p>
            <p class="narrow"><span class="bold">TB Companion Team</span> at the</p>
            <p class="narrow"><span class="bold">DZK - German Central Committee against Tuberculosis e.V.</span></p>
            <p class="narrow">Secretary General: Prof. Dr. T. Bauer (as authorized representative)</p>
            </p>
            <p>
            <p class="narrow">Walterhöferstr. 11, Haus Q</p>
            <p class="narrow">14165 Berlin</p>
            <p class="narrow">Germany</p>
            </p>
            <p>
            <p class="narrow">Phone : +49 (0)30 81 49 09 22</p>
            <p class="narrow">Study Hotline : +49 (0)17 85 28 16 43</p>
            <p class="narrow">E-Mail : info(@)dzk-tuberkulose.de</p>
            </p>
            <p>
            <p class="narrow">Register of Associations Berlin Charlottenburg Amtsgericht</p>
            <p class="narrow">Association register no.: 18796 Nz</p>
            <p class="narrow">Tax number: 27/ 663/ 56016 (Körperschaften I/Berlin)</p>
            </p>
            <p class="seperator">&nbsp;</p>
            <h3>Liability / Limitation of liability</h3>
            <p>
                The contents of our website were created with the greatest possible care and to the best of our
                knowledge. However, we do not assume any liability for the topicality, completeness and correctness of
                the pages. According to § 7 para. 1 TMG (Telemedia Act), we as a service provider are responsible for
                our own content on these pages under the general laws. According to § 8 to 10 TMG, however, we are not
                obligated as a service provider to monitor transmitted or stored third-party information. If we become
                aware of any such infringements, we will remove the relevant content immediately. However, liability in
                this regard is only possible from the time of knowledge of a concrete infringement.
            </p>
            <h3>Medical disclaimer</h3>
            <p>
                The app TB Companion is designed to give personalized information on TB and be a companion during your TB
                diagnosis and treatment. The app and also the webite does not replace the contact with your responsible
                doctors and your responsible health authorities.
            </p>
            <h3>Limitation of liability for external links</h3>
            <p>
                Our website contains links to websites of third parties ("external links"), which are outside our area
                of responsibility. The information provider of the linked websites is liable for the content and
                accuracy of the information. At the time of linking, we were not aware of any legal violations. If we
                become aware of any legal violations, we will remove the corresponding link immediately.
            </p>
            <h3>Copyright</h3>
            <p>
                The content published on the website / in the app TB Companion is subject to German copyright law. The
                reproduction, editing, distribution and any kind of exploitation outside the limits of copyright require
                the prior written consent of the respective author or creator.
            </p>
        </div>
        <img class="bannerimage" src="/banner.png" alt="banner" />
    </div>
    <div v-if="lang=='ro'">
        <div class="content">
            <h1>Imprint</h1>
            <p>
                Imprimare conform §6 MDStV
            </p>
            <p>
            <p class="narrow"><span class="bold">Echipa TB Companion</span> la</p>
            <p class="narrow"><span class="bold">DZK - Comitetul Central german împotriva tuberculozei e.V.</span></p>
            <p class="narrow">Secretar general: Prof. Dr. T. Bauer (în calitate de reprezentant autorizat)</p>
            </p>
            <p>
            <p class="narrow">Walterhöferstr. 11, Haus Q</p>
            <p class="narrow">14165 Berlin</p>
            <p class="narrow">Germania</p>
            </p>
            <p>
            <p class="narrow">Telefon : +49 (0)30 81 49 09 22</p>
            <p class="narrow">Linia fierbinte pentru studii : +49 (0)17 85 28 16 43</p>
            <p class="narrow">E-Mail : info(@)dzk-tuberkulose.de</p>
            </p>
            <p>
            <p class="narrow">Registrul asociațiilor Berlin Charlottenburg Amtsgericht</p>
            <p class="narrow">Registrul Asociatiei nr.: 18796 NZ</p>
            <p class="narrow">Număr impozit: 27/ 663/ 56016 (Körperschaften I/Berlin)</p>
            </p>
            <p class="seperator">&nbsp;</p>
            <h3>Răspunderea/limitarea răspunderii</h3>
            <p>
                Conținutul site-ului nostru a fost creat cu cea mai mare grijă posibilă și cu cele mai înalte cunoștințe ale noastre. Cu toate acestea, nu ne asumăm nicio răspundere pentru actualitatea, exhaustivitatea și corectitudinea paginilor. În conformitate cu § 7 alin. 1 TMG (Legea Telemedia), noi, ca furnizor de servicii, suntem responsabili pentru propriul nostru conținut de pe aceste pagini în conformitate cu legile generale. În conformitate cu § 8 până la 10 TMG, nu suntem obligați ca furnizor de servicii să monitorizăm informațiile transmise sau stocate de terți. Dacă luăm la cunoștință astfel de încălcări, vom elimina imediat conținutul relevant. Cu toate acestea, răspunderea în această privință este posibilă numai din momentul în care se ia la cunoștință o încălcare concretă.
            </p>
            <h3>Disclaimer medical</h3>
            <p>
                Aplicația TB Companion este concepută pentru a oferi informații personalizate despre tuberculoză și pentru a fi un partener în timpul diagnosticării și tratamentului tuberculozei. Nici aplicația  și nici site-ul nu înlocuiesc contactul cu medicii responsabili ai dvs. și autoritățile de sănătate responsabile.
            </p>
            <h3>Limitarea răspunderii pentru link-uri externe</h3>
            <p>
                Site-ul nostru conține link-uri către site-uri ale unor terțe părți ("link-uri externe"), care sunt în afara domeniului nostru de responsabilitate. Furnizorul de informații al site-urilor web atașate este responsabil pentru conținutul și acuratețea informațiilor. La momentul atașării link-urilor nu am fost conștienți de nicio încălcăre legală. Dacă aflăm despre vreo încălcare a legii, vom elimina imediat link-ul corespunzător.
            </p>
            <h3>Copyright</h3>
            <p>
                Conținutul publicat pe site / în aplicația TBCompanion este supus legii germane privind drepturile de autor. Reproducerea, editarea, distribuirea precum și orice fel de exploatare în afara limitelor dreptului de autor necesită acordul prealabil scris al autorului sau creatorului respectiv.
            </p>
        </div>
        <img class="bannerimage" src="/banner.png" alt="banner" />
    </div>
    <div v-if="lang=='uk'">
        <div class="content">
            <h1>Імпринт</h1>
            <p>
                Імпринт згідно з §6 MDStV
            </p>
            <p>
            <p class="narrow"><span class="bold">Команда TB Companion</span> при</p>
            <p class="narrow"><span class="bold">DZK – Некомерційній організації Німецький центральний комітет боротьби з туберкульозом.</span></p>
            <p class="narrow">Генеральний секретар: проф. д-р Т. Бауер (як уповноважений представник)</p>
            </p>
            <p>
            <p class="narrow">Walterhöferstr. 11, Haus Q</p>
            <p class="narrow">14165 Берлін</p>
            <p class="narrow">Німеччина</p>
            </p>
            <p>
            <p class="narrow">Телефон : +49 (0)30 81 49 09 22</p>
            <p class="narrow">Гаряча лінія навчання : +49 (0)17 85 28 16 43</p>
            <p class="narrow">Електронна пошта : info(@)dzk-tuberkulose.de</p>
            </p>
            <p>
            <p class="narrow">Зареєстровано в  районному суді Асоціації Берлін Шарлоттенбург</p>
            <p class="narrow">Реєстраційний номер в асоціації: 18796 Nz</p>
            <p class="narrow">Податковий номер: 27/ 663/ 56016 (Публічно-правова корпорація I/Берлін)</p>
            </p>
            <p class="seperator">&nbsp;</p>
            <h3>Відповідальність / Обмеження відповідальності</h3>
            <p>
                Цей веб-сайт ми створили з максимальною ретельністю та у міру нашої обізнаності. Однак ми не несемо відповідальності за актуальність, повноту та правильність контенту на його сторінках. Відповідно до § 7 пар. 1 TMG (Закону про телемедіа), ми як постачальник послуг несемо відповідальність за власний контент на цих сторінках відповідно до загального законодавства. Відповідно до § 8-10 TMG, ми як постачальник послуг не зобов’язані контролювати передану або збережену інформацію третіх сторін. Якщо нам стане відомо про такі порушення, ми негайно видалимо відповідний контент. Однак відповідальність у цьому відношенні можлива лише з моменту, коли нам стало відомо про конкретне правопорушення.
            </p>
            <h3>Відмова від медичної відповідальності</h3>
            <p>
                Додаток TB Companion створено для того, щоб надавати персоналізовану інформацію про туберкульоз і виступати в ролі компаньйона під час діагностики та лікування туберкульозу. Це не замінює ваше спілкування з відповідними лікарями та органами охорони здоров’я.
            </p>
            <h3>Обмеження відповідальності за зовнішні посилання</h3>
            <p>
                Наш веб-сайт містить посилання на веб-сайти третіх осіб («зовнішні посилання»), які знаходяться за межами нашої сфери відповідальності. Відповідальність за зміст і точність інформації несе постачальник інформації тих веб-сайтів, на які посилаються. На момент створення посилання нам не було відомо про будь-які порушення законодавства. Якщо нам стане відомо про порушення законодавства, ми негайно видалимо відповідне посилання.
            </p>
            <h3>Авторське право</h3>
            <p>
                Контент, викладений на веб-сайті/у додатку TB Companion, регулюється законодавством про авторське право Німеччини. Для відтворення, редагування, розповсюдження та використання поза межами авторського права потрібна попередня письмова згода відповідного автора чи розробника.
            </p>
        </div>
        <img class="bannerimage" src="/banner.png" alt="banner" />
    </div>
</div>