<div class="container">
    <Navigation />
    <Language />
    <div v-if="lang=='en'">
        <div :class="windowWidth >= 892 ? 'content-screen' : 'content'">
            <h1>F.A.Q</h1>            
            <h2>How can I delete my account including all my personal information?</h2>
            <el-row class="support" :gutter="20">
                <el-col :xl="8" :lg="8" :md="8" :sm="12" class="col-screen">
                    <el-image src="/screen_support1.webp" />
                </el-col>
                <el-col :xl="11" :lg="11" :md="11" :sm="12" class="col-screen">
                    <h3>From the main menu</h3>
                    <p>Use the profile button</p>
                    <el-image class="image-button" src="/screen_support_button_profile.webp" />
                    <p>to open up the profile screen.</p>
                </el-col>
            </el-row>
            <el-row class="support" :gutter="20">
                <el-col :xl="8" :lg="8" :md="8" :sm="12" class="col-screen">
                    <el-image src="/screen_support2.webp" />
                </el-col>
                <el-col :xl="11" :lg="11" :md="11" :sm="12" class="col-screen">
                    <h3>On the profile screen</h3>
                    <p>Use the companion history button</p>
                    <el-image class="image-button" src="/screen_support_button_history.webp" />
                    <p>to open up the history screen.</p>
                </el-col>
            </el-row>
            <el-row class="support" :gutter="20">
                <el-col :xl="8" :lg="8" :md="8" :sm="12" class="col-screen">
                    <el-image src="/screen_support3.webp" />
                </el-col>
                <el-col :xl="11" :lg="11" :md="11" :sm="12" class="col-screen">
                    <h3>On the history screen</h3>
                    <p>you can see the complete conversation history between you and your companion.</p>
                    <p>Click on the exit button</p>
                    <el-image class="image-button" src="/screen_support_button_quit.webp" />
                    <p>to quit TB Companion.</p>
                </el-col>
            </el-row>
            <el-row class="support" :gutter="20">
                <el-col :xl="8" :lg="8" :md="8" :sm="12" class="col-screen">
                    <el-image src="/screen_support4.webp" />
                </el-col>
                <el-col :xl="11" :lg="11" :md="11" :sm="12" class="col-screen">
                    <h3>On the termination screen</h3>
                    <p>you can choose the reason why you want to quit from one of the options.</p>
                    <p>Click the confirmation button</p>
                    <el-image class="image-button" src="/screen_support_button_confirm.webp" />
                    <p>to confirm the deletion of your account, including all your personal information.</p>
                    <p>Please be aware that this step cannot be undone.</p>
                    <p>If you would like to use the app again, you need to restart the app and register again.</p>
                    <p>Use the cancel button</p>
                    <el-image class="image-button" src="/screen_support_button_cancel.webp" />
                    <p>to cancel the termination process and return to the previous screen.</p>
                </el-col>
            </el-row>
        </div>
    </div>
    <div v-if="lang=='ro'">
        <div :class="windowWidth >= 892 ? 'content-screen' : 'content'">
            <h1>F.A.Q</h1>            
            <h2>Cum îmi pot șterge contul, inclusiv toate informațiile mele personale?</h2>
            <el-row class="support" :gutter="20">
                <el-col :xl="8" :lg="8" :md="8" :sm="12" class="col-screen">
                    <el-image src="/screen_support1.webp" />
                </el-col>
                <el-col :xl="11" :lg="11" :md="11" :sm="12" class="col-screen">
                    <h3>Din meniul principal</h3>
                    <p>Folosiți butonul de profil</p>
                    <el-image class="image-button" src="/screen_support_button_profile.webp" />
                    <p>pentru a deschide ecranul de profil.</p>
                </el-col>
            </el-row>
            <el-row class="support" :gutter="20">
                <el-col :xl="8" :lg="8" :md="8" :sm="12" class="col-screen">
                    <el-image src="/screen_support2.webp" />
                </el-col>
                <el-col :xl="11" :lg="11" :md="11" :sm="12" class="col-screen">
                    <h3>Pe ecranul profilului</h3>
                    <p>Folosiți butonul istoric însoțitor</p>
                    <el-image class="image-button" src="/screen_support_button_history.webp" />
                    <p>pentru a deschide ecranul istoric.</p>
                </el-col>
            </el-row>
            <el-row class="support" :gutter="20">
                <el-col :xl="8" :lg="8" :md="8" :sm="12" class="col-screen">
                    <el-image src="/screen_support3.webp" />
                </el-col>
                <el-col :xl="11" :lg="11" :md="11" :sm="12" class="col-screen">
                    <h3>Pe ecranul istoric</h3>
                    <p>poți vedea istoricul complet al conversațiilor dintre tine și însoțitorul tău.</p>
                    <p>Faceți clic pe butonul de ieșire</p>
                    <el-image class="image-button" src="/screen_support_button_quit.webp" />
                    <p>pentru a părăsi TB Companion.</p>
                </el-col>
            </el-row>
            <el-row class="support" :gutter="20">
                <el-col :xl="8" :lg="8" :md="8" :sm="12" class="col-screen">
                    <el-image src="/screen_support4.webp" />
                </el-col>
                <el-col :xl="11" :lg="11" :md="11" :sm="12" class="col-screen">
                    <h3>Pe ecranul de terminare</h3>
                    <p>puteți alege motivul pentru care doriți să renunțați dintr-una dintre opțiuni.</p>
                    <p>Faceți clic pe butonul de confirmare</p>
                    <el-image class="image-button" src="/screen_support_button_confirm.webp" />
                    <p>pentru a confirma ștergerea contului dvs., inclusiv a tuturor informațiilor dvs. personale.</p>
                    <p>Vă rugăm să rețineți că acest pas nu poate fi anulat.</p>
                    <p>Dacă doriți să utilizați din nou aplicația, trebuie să reporniți aplicația și să vă înregistrați din nou.</p>
                    <p>Folosiți butonul de anulare</p>
                    <el-image class="image-button" src="/screen_support_button_cancel.webp" />
                    <p>pentru a anula procesul de terminare și a reveni la ecranul anterior.</p>
                </el-col>
            </el-row>
        </div>
    </div>
    <div v-if="lang=='uk'">
        <div :class="windowWidth >= 892 ? 'content-screen' : 'content'">
            <h1>F.A.Q</h1>            
            <h2>Як я можу видалити свій обліковий запис разом із усією особистою інформацією?</h2>
            <el-row class="support" :gutter="20">
                <el-col :xl="8" :lg="8" :md="8" :sm="12" class="col-screen">
                    <el-image src="/screen_support1.webp" />
                </el-col>
                <el-col :xl="11" :lg="11" :md="11" :sm="12" class="col-screen">
                    <h3>З головного меню</h3>
                    <p>Використовуйте кнопку профілю</p>
                    <el-image class="image-button" src="/screen_support_button_profile.webp" />
                    <p>щоб відкрити екран профілю.</p>
                </el-col>
            </el-row>
            <el-row class="support" :gutter="20">
                <el-col :xl="8" :lg="8" :md="8" :sm="12" class="col-screen">
                    <el-image src="/screen_support2.webp" />
                </el-col>
                <el-col :xl="11" :lg="11" :md="11" :sm="12" class="col-screen">
                    <h3>На екрані профілю</h3>
                    <p>Використовуйте кнопку історії компаньйонів</p>
                    <el-image class="image-button" src="/screen_support_button_history.webp" />
                    <p>щоб відкрити екран історії.</p>
                </el-col>
            </el-row>
            <el-row class="support" :gutter="20">
                <el-col :xl="8" :lg="8" :md="8" :sm="12" class="col-screen">
                    <el-image src="/screen_support3.webp" />
                </el-col>
                <el-col :xl="11" :lg="11" :md="11" :sm="12" class="col-screen">
                    <h3>На екрані історії</h3>
                    <p>ви можете переглянути повну історію розмов між вами та вашим супутником.</p>
                    <p>Натисніть на кнопку виходу</p>
                    <el-image class="image-button" src="/screen_support_button_quit.webp" />
                    <p>щоб вийти з TB Companion.</p>
                </el-col>
            </el-row>
            <el-row class="support" :gutter="20">
                <el-col :xl="8" :lg="8" :md="8" :sm="12" class="col-screen">
                    <el-image src="/screen_support4.webp" />
                </el-col>
                <el-col :xl="11" :lg="11" :md="11" :sm="12" class="col-screen">
                    <h3>На екрані завершення</h3>
                    <p>ви можете вибрати причину, чому ви хочете вийти з одного з варіантів.</p>
                    <p>Натисніть кнопку підтвердження</p>
                    <el-image class="image-button" src="/screen_support_button_confirm.webp" />
                    <p>щоб підтвердити видалення вашого облікового запису, включаючи всю вашу особисту інформацію.</p>
                    <p>Майте на увазі, що цей крок не можна скасувати.</p>
                    <p>Якщо ви хочете використовувати програму знову, вам потрібно перезапустити програму та зареєструватися знову.</p>
                    <p>Скористайтеся кнопкою скасування</p>
                    <el-image class="image-button" src="/screen_support_button_cancel.webp" />
                    <p>щоб скасувати процес завершення та повернутися до попереднього екрана.</p>
                </el-col>
            </el-row>
        </div>
    </div>
    <img class="bannerimage" src="/banner.png" alt="banner" />
</div>