import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home/Home.vue';
import About from '../views/About/About.vue';
import Team from '../views/Team/Team.vue';
import Contact from '../views/Contact/Contact.vue';
import Roger from '../views/Roger/Roger.vue';
import Privacy from '../views/Privacy/Privacy.vue';
import Imprint from '../views/Imprint/Imprint.vue';
import Support from '../views/Support/Support.vue';
import Explaintb from '../views/Explaintb/Explaintb.vue';

const routes: Array<RouteRecordRaw> = 
[
    {
        path: '/',
        redirect: '/home/en',
    },
    {
        path: '/home/:lang',
        name: 'home',
        component: Home,
    },
    {
        path: '/about',
        redirect: '/about/en',
    },
    {
        path: '/about/:lang',
        name: 'about',
        component: About,
    },
    {
        path: '/team',
        redirect: '/team/en',
    },
    {
        path: '/team/:lang',
        name: 'team',
        component: Team,
    },
    {
        path: '/contact',
        redirect: '/contact/en',
    },
    {
        path: '/contact/:lang',
        name: 'contact',
        component: Contact,
    },
    {
        path: '/roger',
        redirect: '/roger/en',
    },
    {
        path: '/roger/:lang',
        name: 'roger',
        component: Roger,
    },
    {
        path: '/privacy',
        redirect: '/privacy/en',
    },
    {
        path: '/privacy/:lang',
        name: 'privacy',
        component: Privacy,
    },
    {
        path: '/imprint',
        redirect: '/imprint/en',
    },
    {
        path: '/imprint/:lang',
        name: 'imprint',
        component: Imprint,
    },
    {
        path: '/support',
        redirect: '/support/en',
    },
    {
        path: '/support/:lang',
        name: 'support',
        component: Support,
    },
    {
        path: '/explaintb',
        name: 'explaintb',
        component: Explaintb,
    },
]

const router = createRouter({history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH), routes});

export default router
