import { Component, Vue } from 'vue-facing-decorator';
import Navigation from '@/components/Navigation/Navigation.vue';
import Language from '@/components/Language/Language.vue';

@Component(
{
    components:
    {
        Navigation,
        Language
    },
})

export default class Imprint extends Vue 
{
    private loading : boolean = true;
    private lang : string = 'en';
    private windowWidth : number = window.innerWidth;
    private windowHeight : number = window.innerHeight;

    private async mounted()
    {
        // fetch language from params
        this.lang = this.$route.params.lang as string;
        
        // set loading indicator
        this.loading = false;

        window.addEventListener('resize', this.onWindowResize);
        window.scrollTo(0,0);
    }

    private onWindowResize(e : any)
    {
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
    }
}