import { Component, Vue } from 'vue-facing-decorator';

@Component(
{
    components:
    {
    },
})

export default class Language extends Vue 
{
    private language : string = 'en';
    private selectedLanguage : string = 'English';
    private isUnavailable : boolean = false;
    private hintText : string = '';
    
    private async mounted()
    {
        // get language from url parameter, or load from local storage
        if (this.$route.params.lang)
        {
            this.language = this.$route.params.lang as string;
        }

        // if no language is given, try to set according to browser settings
        if (!this.language || this.language == '?')
        {
            if (navigator.language.toLowerCase().includes('ro'))
            {
                this.language = 'ro';
            }
            
            if (navigator.language.toLowerCase().includes('uk'))
            {
                this.language = 'uk';
            }
        }   

        switch (this.language)
        {
            case 'ro':
                this.hintText = "Ne pare rău, traducerea în română va veni în curând.";
                this.selectedLanguage = 'Română';
                break;

            case 'uk':
                this.hintText = "Вибачте, незабаром буде переклад румунською.";
                this.selectedLanguage = 'українська';
                break;
        };

    }

    public onLanguageClick(lang : string) 
    {
        this.$router.push(`/${this.$router.currentRoute.value.name as string}/${lang}`).catch(()=>{});
    }
}
